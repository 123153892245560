// src/firebase/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAwrnSb-V8xbh2SR5dQaEiHwdk9MXwhCb4",
  authDomain: "aborrasdesign-b281d.firebaseapp.com",
  projectId: "aborrasdesign-b281d",
  storageBucket: "aborrasdesign-b281d.appspot.com",
  messagingSenderId: "217056402289",
  appId: "1:217056402289:web:fcdeceadfa0a7ebd4834d0",
  measurementId: "G-61DM4VL3HM",
};

// Inicialitza Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Exporta l'autenticació
const db = getFirestore(app); // Exporta Firestore com 'db'

// Conectar-se als emuladors si estem en desenvolupament
if (process.env.NODE_ENV === 'development') {
  console.log('Connectant-se als emuladors de Firebase...');
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, "http://localhost:9099");
} else {
  console.log('Connectant-se a la base de dades de producció de Firebase.');
}

export { auth, db };
export default app;